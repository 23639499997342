import Color from 'color';
import { DefaultTheme, StaticColors, StaticLightAndDarkTheme } from 'styled-components';

import ThemeModel from '@Api/models/ThemeModel';

import {
  adjustAlpha,
  blendHexColors,
  changeTowardsContrast,
  getContrastTextColor,
  getGradientColorAtPosition,
  grayscaleWithAlpha,
} from '@Components/helper/styleHelper';

export const iconSize = 24;
export const iconStroke = 1.25;

export const colorWhite = '#FFFFFFFF';
export const colorBlack = '#000000FF';

export enum ThemeType {
  Light = 'light',
  Dark = 'dark',
}

export enum ThemeIconSet {
  Normal = 'normal',
  Desaturated = 'desaturated',
}

// should only be used to calculate fallback DefaultTheme if api can not be reached
// todo: keep up to date with api light theme
const fallbackLightTheme: ThemeModel = {
  id: 'XXXXX',
  isPublished: true,
  name: 'Default (Light)',
  themeType: ThemeType.Light,
  iconSet: ThemeIconSet.Normal,
  colors: {
    primary: '#7F56D9FF',
    accent: '#D97F56FF',
    primaryText: '#101423FF',
    link: '#2650BCFF',
    backgroundGradientStart: '#E5F3F8FF',
    backgroundGradientEnd: '#FFFFFFFF',
    mainGradientStart: '#6CA5FBFF',
    mainGradientEnd: '#DB00FFFF',
    backgroundAccent: '#FFFFFFFF',
    backgroundHeader: '#f1fcffFF',
    backgroundBox: '#FFFFFFCC',
    backgroundInputButton: '#FFFFFFFF',
    backgroundFooter: '#101026FF',
    footerText: '#FFFFFFFF',
    border: '#F2F2F2FF',
    shadow: '#0000001A',
    wordBoxActive: '#FFFF00FF',
    wordBoxTyped: '#ADD8E6FF',
    wordBoxCursor: '#C06E00FF',
    success: '#5EB623FF',
    info: '#2B6BB3FF',
    warning: '#CE9117FF',
    error: '#E22F2FFF',
    backgroundChart1GradientStart: '#7F56D9FF',
    backgroundChart1GradientEnd: '#C7E7EFFF',
    backgroundChart2GradientStart: '#D64DD1FF',
    backgroundChart2GradientEnd: '#59E0D8FF',
  },
};

// static colors that cannot be changed,
// but that change slightly depending on whether the user chooses a dark or a light theme
const staticLightAndDarkTheme: StaticLightAndDarkTheme = {
  light: {
    text: {
      logo: '#444444FF',
      header: '#3F455AFF',
    },
    winner: '#FFC87CFF',
  },
  dark: {
    text: {
      logo: '#FFFFFFFF',
      header: '#FFFFFFFF',
    },
    winner: '#985800FF',
  },
};

// the static theme contains all values that must always be the same,
// regardless of the theme. This may have legal or functional reasons
const staticColors: StaticColors = {
  casualMatchPosition: {
    first: '#FEBC13FF',
    second: '#BBD8DEFF',
    third: '#c98566FF',
  },
  confetti: ['#00FFFCFF', '#FC00FFFF', '#fffc00FF'],
  discordButton: {
    background: '#5865F2FF',
    border: '#5865F2FF',
    text: '#FFFFFFFF',
  },
  facebookButton: {
    background: '#1877f2FF',
    border: '#1877f2FF',
    text: '#FFFFFFFF',
  },
  googleButton: {
    background: '#FFFFFFFF',
    border: '#D9D9D9FF',
    text: '#0000008A',
  },
  historyHeatmap: {
    step1: '#e442a3FF',
    step2: '#ff4a82FF',
    step3: '#ff695dFF',
    step4: '#ff9137FF',
    step5: '#ffba06FF',
    step6: '#e3e00aFF',
  },
  scale: {
    start: '#6dac25FF',
    middle: '#d4d315FF',
    end: '#df2f83FF',
  },
  modalBackdrop: '#000000BF',
  white: colorWhite,
  black: colorBlack,
};

export const getDefaultTheme = (initBaseColors?: ThemeModel): DefaultTheme => {
  const baseColors = initBaseColors ?? fallbackLightTheme;

  if (baseColors.colors === undefined || baseColors.themeType === undefined || baseColors.iconSet === undefined) {
    throw Error('Unexpected Error: theme is partially undefined');
  }

  return {
    name: baseColors.name,
    type: baseColors.themeType,
    iconSet: baseColors.iconSet,
    borderRadius: {
      button: '6px',
      box: '10px',
      input: '0.175rem',
    },
    device: {
      mobile: '768px',
      tablet: '940px',
      desktop: '1200px',
    },
    font: {
      primaryFamily: 'Inter, sans-serif',
      headlineFamily: 'Work Sans, sans-serif',
      typingBoxWordsFont1: 'Roboto Slab, serif',
      typingBoxWordsFont2: 'Roboto Mono',
      typingBoxWordsFont3: 'Roboto, sans-serif',
      typingBoxWordsFont4: 'Monoid',
      headline: {
        one: {
          fontSize: '52px',
          fontWeight: '300',
          lineHeight: '120%',
          letterSpacing: 'normal',
        },
        two: {
          fontSize: '32px',
          fontWeight: '300',
          lineHeight: '120%',
          letterSpacing: 'normal',
        },
        three: {
          fontSize: '24px',
          fontWeight: '300',
          lineHeight: '120%',
          letterSpacing: 'normal',
        },
        four: {
          fontSize: '18px',
          fontWeight: '300',
          lineHeight: '120%',
          letterSpacing: 'normal',
        },
        five: {
          fontSize: '14px',
          fontWeight: '300',
          lineHeight: '120%',
          letterSpacing: 'normal',
        },
      },
      text: {
        light: {
          xs: {
            fontSize: '12px',
            fontWeight: '300',
            lineHeight: '165%',
            letterSpacing: 'normal',
          },
          small: {
            fontSize: '14px',
            fontWeight: '300',
            lineHeight: '165%',
            letterSpacing: 'normal',
          },
          medium: {
            fontSize: '16px',
            fontWeight: '300',
            lineHeight: '165%',
            letterSpacing: 'normal',
          },
          large: {
            fontSize: '18px',
            fontWeight: '300',
            lineHeight: '165%',
            letterSpacing: 'normal',
          },
        },
        semibold: {
          xs: {
            fontSize: '12px',
            fontWeight: '600',
            lineHeight: '165%',
            letterSpacing: 'normal',
          },
          small: {
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '165%',
            letterSpacing: 'normal',
          },
          medium: {
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '165%',
            letterSpacing: 'normal',
          },
          large: {
            fontSize: '18px',
            fontWeight: '600',
            lineHeight: '165%',
            letterSpacing: 'normal',
          },
        },
      },
    },
    space: {
      xs_4: '0.25rem',
      small_8: '0.5rem',
      default_16: '1rem',
      large_32: '2rem',
      xl_64: '4rem',
    },
    transition: {
      theme: '200ms ease-in',
    },
    palette: {
      static: staticColors,
      background: {
        header: baseColors.colors.backgroundHeader,
        mainGradient: {
          start: baseColors.colors.backgroundGradientStart,
          end: baseColors.colors.backgroundGradientEnd,
        },
        accent: baseColors.colors.backgroundAccent,
        chart1Gradient: {
          start: baseColors.colors.backgroundChart1GradientStart,
          end: baseColors.colors.backgroundChart1GradientEnd,
        },
        chart2Gradient: {
          start: baseColors.colors.backgroundChart2GradientStart,
          end: baseColors.colors.backgroundChart2GradientEnd,
        },
      },
      badge: {
        primary: {
          background: baseColors.colors.primary,
          text: getContrastTextColor(baseColors.colors.primary),
        },
        secondary: {
          background: baseColors.colors.accent,
          text: getContrastTextColor(baseColors.colors.accent),
        },
        tertiary: {
          background: Color(baseColors.colors.primary).rotate(320).hexa(),
          text: getContrastTextColor(Color(baseColors.colors.primary).rotate(320).hexa()),
        },
        error: {
          background: baseColors.colors.error,
          text: getContrastTextColor(baseColors.colors.error),
        },
        level: {
          background: baseColors.colors.primary,
          text: getContrastTextColor(baseColors.colors.primary),
        },
      },
      box: {
        background: {
          primary: baseColors.colors.backgroundBox,
          secondary: changeTowardsContrast(baseColors.colors.backgroundBox, 0.03),
          hover: Color(baseColors.colors.primary).mix(Color(baseColors.colors.backgroundBox), 0.9).hexa(),
        },
        border: {
          default: Color(baseColors.colors.backgroundBox).darken(0.1).hexa(),
          gradient: {
            start: baseColors.colors.mainGradientStart,
            end: baseColors.colors.mainGradientEnd,
          },
        },
      },
      button: {
        primary: {
          background: baseColors.colors.primary,
          border: baseColors.colors.primary,
          text: getContrastTextColor(baseColors.colors.primary),
          hoverBorder: grayscaleWithAlpha(baseColors.colors.primary),
        },
        secondary: {
          background: baseColors.colors.backgroundInputButton,
          border: Color(grayscaleWithAlpha(baseColors.colors.backgroundInputButton)).darken(0.2).hexa(),
          text: baseColors.colors.primaryText,
          hoverBorder: changeTowardsContrast(baseColors.colors.backgroundInputButton, 0.3),
        },
        danger: {
          background: baseColors.colors.error,
          border: baseColors.colors.error,
          text: getContrastTextColor(baseColors.colors.error),
          hoverBorder: grayscaleWithAlpha(baseColors.colors.primary),
        },
        shadow: baseColors.colors.shadow,
      },
      footer: {
        background: baseColors.colors.backgroundFooter,
        text: baseColors.colors.footerText,
        textLight: changeTowardsContrast(baseColors.colors.footerText, 0.1), //#9ca3af
      },
      graph: {
        primary: baseColors.colors.primary,
        secondary: baseColors.colors.accent,
        heatmap: {
          zero: changeTowardsContrast(baseColors.colors.backgroundBox, 0.075),
          min: Color(baseColors.colors.backgroundBox).mix(Color(baseColors.colors.primary), 0.1).hexa(),
          max: baseColors.colors.primary,
          border: blendHexColors(
            getGradientColorAtPosition(
              baseColors.colors.backgroundGradientStart,
              baseColors.colors.backgroundGradientEnd,
              20
            ).hex(),
            baseColors.colors.backgroundBox
          ),
        },
      },
      spinner: {
        default: baseColors.colors.primary,
        light: Color(baseColors.colors.primary).mix(Color(baseColors.colors.backgroundBox), 0.5).hexa(),
        contrast: getContrastTextColor(baseColors.colors.primary),
      },
      icon: {
        background: Color(baseColors.colors.primaryText).mix(Color(baseColors.colors.backgroundBox), 0.9).hexa(),
        saturation: baseColors.iconSet === ThemeIconSet.Desaturated ? 'saturate(75%)' : 'none',
        primary: baseColors.colors.primaryText,
        accent: changeTowardsContrast(baseColors.colors.primary, 0.3),
        primaryHeader: Color(baseColors.colors.backgroundHeader).isLight()
          ? staticLightAndDarkTheme.light.text.header
          : changeTowardsContrast(staticLightAndDarkTheme.dark.text.header, 0.3),
        accentHeader: Color(baseColors.colors.backgroundHeader).isLight()
          ? baseColors.colors.primary
          : changeTowardsContrast(baseColors.colors.primary, 0.3),
      },
      input: {
        background: {
          default: baseColors.colors.backgroundInputButton,
          disabled: Color(baseColors.colors.backgroundInputButton).darken(0.05).hexa(),
          active: Color(baseColors.colors.primary).mix(Color(baseColors.colors.backgroundInputButton), 0.55).hexa(),
          hover: Color(baseColors.colors.primary).mix(Color(baseColors.colors.backgroundInputButton), 0.75).hexa(),
        },
        border: {
          default: Color(grayscaleWithAlpha(baseColors.colors.backgroundInputButton)).darken(0.2).hexa(),
          hover: changeTowardsContrast(baseColors.colors.backgroundInputButton, 0.4),
          error: baseColors.colors.error,
          active: baseColors.colors.primary,
        },
        switch: {
          active: baseColors.colors.primary,
          inactive: Color(baseColors.colors.backgroundInputButton).darken(0.2).hexa(),
        },
        bar: {
          background: changeTowardsContrast(baseColors.colors.backgroundInputButton, 0.2),
          progress: baseColors.colors.primary,
        },
      },
      image: {
        border:
          baseColors.themeType === ThemeType.Light
            ? changeTowardsContrast(staticColors.black, 0.75)
            : changeTowardsContrast(staticColors.white, 0.6),
      },
      popOver: {
        background: {
          default:
            baseColors.themeType === ThemeType.Light
              ? colorWhite
              : Color(colorBlack).mix(Color(baseColors.colors.primary), 0.1).hexa(),
          hover:
            baseColors.themeType === ThemeType.Light
              ? Color(colorWhite).mix(Color(baseColors.colors.primary), 0.25).hexa()
              : Color(colorBlack).mix(Color(baseColors.colors.primary), 0.4).hexa(),
          selected:
            baseColors.themeType === ThemeType.Light
              ? Color(colorWhite).mix(Color(baseColors.colors.primary), 0.4).hexa()
              : Color(colorBlack).mix(Color(baseColors.colors.primary), 0.5).hexa(),
        },
        border:
          baseColors.themeType === ThemeType.Light
            ? Color(colorWhite).darken(0.1).hexa()
            : Color(colorBlack).mix(Color(baseColors.colors.primary), 0.1).darken(0.1).hexa(),
        shadow: baseColors.colors.shadow,
      },
      skeleton: Color(getContrastTextColor(baseColors.colors.backgroundBox)).alpha(0.07).hexa(),
      table: {
        row: {
          highlight: Color(baseColors.colors.primary).mix(Color(baseColors.colors.backgroundBox), 0.7).hexa(),
          hover: Color(baseColors.colors.primary).mix(Color(baseColors.colors.backgroundBox), 0.9).hexa(),
          even: changeTowardsContrast(baseColors.colors.backgroundInputButton, 0.05),
          winner:
            baseColors.themeType === ThemeType.Light
              ? staticLightAndDarkTheme.light.winner
              : staticLightAndDarkTheme.dark.winner,
          info: Color(baseColors.colors.primary).mix(Color(baseColors.colors.backgroundBox), 0.7).hexa(),
        },
        headline: {
          border: changeTowardsContrast(baseColors.colors.backgroundInputButton, 0.05),
        },
      },
      divider: {
        default: baseColors.colors.border,
        header: adjustAlpha(changeTowardsContrast(baseColors.colors.backgroundHeader, 0.4), 0.25),
        gradient: {
          start: baseColors.colors.mainGradientStart,
          end: baseColors.colors.mainGradientEnd,
        },
      },
      message: {
        success: {
          text: baseColors.colors.success,
          background:
            baseColors.themeType === ThemeType.Light
              ? adjustAlpha(baseColors.colors.success, 0.1)
              : adjustAlpha(baseColors.colors.success, 0.25),
        },
        info: {
          text: baseColors.colors.info,
          background:
            baseColors.themeType === ThemeType.Light
              ? adjustAlpha(baseColors.colors.info, 0.1)
              : adjustAlpha(baseColors.colors.info, 0.2),
        },
        warning: {
          text: baseColors.colors.warning,
          background:
            baseColors.themeType === ThemeType.Light
              ? adjustAlpha(baseColors.colors.warning, 0.1)
              : adjustAlpha(baseColors.colors.warning, 0.2),
        },
        error: {
          text: baseColors.colors.error,
          background:
            baseColors.themeType === ThemeType.Light
              ? adjustAlpha(baseColors.colors.error, 0.1)
              : adjustAlpha(baseColors.colors.error, 0.2),
        },
      },
      multiplayer: {
        progress: {
          startEndDot: baseColors.colors.backgroundGradientStart,
          users: Color(grayscaleWithAlpha(baseColors.colors.primary)).lighten(0.4).hexa(),
          currentUser: baseColors.colors.primary,
          line: baseColors.colors.primary,
          dotBorder: baseColors.colors.primary,
          accuracyDotBorder: Color(baseColors.colors.primary).rotate(90).hexa(),
        },
      },
      result: {
        textPrimary:
          Color(baseColors.colors.primary).isLight() && Color(baseColors.colors.backgroundGradientStart).isLight()
            ? changeTowardsContrast(baseColors.colors.primary, 0.15)
            : baseColors.colors.primary,
        textSecondary: Color(baseColors.colors.primaryText)
          .mix(Color(baseColors.colors.backgroundGradientStart), 0.6)
          .hexa(),
        divider: Color(baseColors.colors.primaryText)
          .mix(Color(baseColors.colors.backgroundGradientStart), 0.85)
          .hexa(),
        chartPrimary:
          Color(baseColors.colors.primary).isLight() && Color(baseColors.colors.backgroundGradientStart).isLight()
            ? changeTowardsContrast(baseColors.colors.primary, 0.15)
            : baseColors.colors.primary,
        chartError: baseColors.colors.error,
        chartDivider: Color(baseColors.colors.primaryText)
          .mix(Color(baseColors.colors.backgroundGradientEnd), 0.85)
          .hexa(),
      },
      achievement: {
        primaryChart: baseColors.colors.primary,
        secondaryChart: baseColors.colors.accent,
        border: baseColors.colors.border,
        background:
          baseColors.themeType === ThemeType.Light
            ? staticColors.white
            : changeTowardsContrast(staticColors.black, 0.1),
        backgroundBadge: Color(baseColors.colors.primary).mix(Color(staticColors.black), 0.8).hexa(),
        backgroundCircle: adjustAlpha(Color(baseColors.colors.primary).rotate(270).hexa(), 0.2),
        rarity: {
          common: staticColors.scale.start,
          uncommon: Color(staticColors.scale.start).mix(Color(staticColors.scale.middle), 0.5).hexa(),
          rare: staticColors.scale.middle,
          epic: Color(staticColors.scale.middle).mix(Color(staticColors.scale.end), 0.5).hexa(),
          legendary: staticColors.scale.end,
        },
        progress: {
          level: {
            reached: baseColors.colors.primary,
            notReached:
              baseColors.themeType === ThemeType.Light
                ? changeTowardsContrast(staticColors.white, 0.4)
                : changeTowardsContrast(staticColors.black, 0.4),
          },
          barBackground:
            baseColors.themeType === ThemeType.Light
              ? changeTowardsContrast(staticColors.white, 0.2)
              : changeTowardsContrast(staticColors.black, 0.7),
        },
      },
      text: {
        primary: baseColors.colors.primaryText,
        secondary: Color(baseColors.colors.primaryText).mix(Color(baseColors.colors.backgroundBox), 0.2).hexa(),
        tertiary: Color(baseColors.colors.primaryText).mix(Color(baseColors.colors.backgroundBox), 0.4).hexa(),
        link: baseColors.colors.link,
        error: baseColors.colors.error,
        themePrimary: baseColors.colors.primary,
        header: Color(baseColors.colors.backgroundHeader).isLight()
          ? staticLightAndDarkTheme.light.text.header
          : staticLightAndDarkTheme.dark.text.header,
        logo: {
          header: Color(baseColors.colors.backgroundHeader).isLight()
            ? staticLightAndDarkTheme.light.text.logo
            : staticLightAndDarkTheme.dark.text.logo,
          default: Color(baseColors.colors.backgroundGradientStart).isLight()
            ? staticLightAndDarkTheme.light.text.logo
            : staticLightAndDarkTheme.dark.text.logo,
        },
      },
      wordBox: {
        active: baseColors.colors.wordBoxActive,
        error: baseColors.colors.error,
        typed: baseColors.colors.wordBoxTyped,
        cursor: baseColors.colors.wordBoxCursor,
      },
    },
  };
};
